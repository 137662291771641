@import '../../../../../theme/index';
@import '../../../../../theme/media';

$filterWidth: 150px;

@mixin containerStyle() {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  @include respond-to($phone-media) {
    padding: $smallSpace;
  }

  @include respond-to($tablet-media, $desktop-media) {
    padding: $mediumSpace;
  }
}

.FilterSection {
  display: grid;

  @include respond-to($phone-media) {
    grid-row-gap: $smallSpace;
    grid-template-areas: 'SelectFiltersSection' 'ActiveFiltersSection';
  }

  @include respond-to($tablet-media, $desktop-media) {
    grid-column-gap: $mediumSpace;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'SelectFiltersSection ActiveFiltersSection';
    width: calc(100% - #{$mediumSpace});
  }

  &__SelectFiltersSection {
    grid-area: SelectFiltersSection;

    &_title {
      background-color: $primaryYellow;
      color: $black !important;
    }

    &_container {
      @include containerStyle();
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto !important;

      button {
        @include respond-to($phone-media) {
          display: none !important;
        }
      }
    }

    &_loading_container {
      display: flex;
      justify-content: center;
      padding: $smallSpace 0;
    }

    &_filter {
      padding: 0 $mediumSpace;
      margin: 0 0 0 $smallSpace !important;
    }

    &_FilterSelect {
      align-items: flex-end;
      display: flex;
      margin-right: $smallSpace;

      &_select {
        max-width: $filterWidth;
      }
    }
  }

  &__ActiveFiltersSection {
    display: flex;
    flex-direction: column;
    grid-area: ActiveFiltersSection;

    &_title {
      @include respond-to($phone-media) {
        display: none;
      }

      background-color: $primaryYellow;
      color: $black !important;
    }

    &_container {
      @include containerStyle();
    }

    &_filter {
      margin: 0 0 $smallSpace $smallSpace !important;
    }
  }
}

.AutoCompleteFilter {
  &__main {
    display: flex;
    align-items: flex-end;

    > div {
      width: $filterWidth !important;
    }
  }

  &__autocomplete {
    width: $filterWidth !important;
    font-size: $mediumSpace !important;
  }
}
