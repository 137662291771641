@import '../../theme/index.scss';
@import '../../theme/mixins.scss';

.CompetitionPriority {
  display: flex;
  flex-direction: column;
  padding: $mediumSpace;

  @include respond-to($phone-media) {
    padding: $smallSpace 0;
  }
  &_sports {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    &_sport {
      cursor: pointer;
      border-radius: 16px;
      background-color: white;
      border: 1px solid black;
      margin: 4px;
      padding: 6px 12px;
      &_selected {
        @extend .CompetitionPriority_sports_sport;
        border: 1px solid $primaryYellow;
        background-color: $primaryYellow;
      }
    }
  }

  &__error {
    @include respond-to($desktop-media) {
      margin: 0 $smallSpace $mediumSpace;
    }

    @include respond-to($tablet-media) {
      margin: 0 $verySmallSpace $smallSpace;
    }

    @include respond-to($phone-media) {
      margin: 0 0 $smallSpace;
    }
  }

  &__DnD_container {
    flex: 1;
  }
}
