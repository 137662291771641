// Theme
@import '../../../theme/index.scss';
@import '../../../theme/media.scss';

.PublicPage {
  &_link {
    color: $primaryRed;
  }

  &__background {
    width: 100%;
    height: 100vh;
    background-color: $black;

    display: flex;

    @include respond-to($phone-media) {
      flex-direction: column;
    }

    &_left_banner {
      background-color: $primaryYellow;
      padding: $largeSpace;
      z-index: 0;

      @include respond-to($phone-media) {
        height: 10%;
      }

      @include respond-to($tablet-media, $desktop-media) {
        width: 10%;
      }
    }

    &_mp_logo {
      margin-left: auto;
      margin-right: auto;
      display: block;

      @include respond-to($desktop-media, $tablet-media) {
        width: 85%;
      }

      @include respond-to($phone-media) {
        width: 45%;
      }
    }

    @include respond-to($tablet-media, $desktop-media) {
      &_main {
        width: 90%;
        background-position: right bottom;
        background-size: 45%;
      }
    }
  }

  &__main {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include respond-to($phone-media) {
      & > * {
        width: 80%;
      }
    }
  }
}
