@import '../../../theme/index.scss';
@import '../../../theme/media.scss';

.Data {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid $lightGrey;
  &_row {
    display: flex;
  }
  &_cell {
    background-color: $white;
    margin: 0;
    padding: $smallSpace;
    border: 1px solid $lightGrey;
    font-size: 12px;
    width: 200px;
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    @include respond-to($phone-media) {
      width: 50px;
    }
  }
}
