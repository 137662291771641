@import '../../theme/index.scss';
@import '../../theme/media.scss';

.MpHeader {
  position: relative;
  text-align: center;
  width: 100%;

  &__appbar {
    @include respond-to($desktop-media) {
      display: none !important;
    }
  }
}
