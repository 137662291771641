@import '../../../../theme/index.scss';
.ResetAnswers {
  margin: $smallSpace 0;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: $smallSpace;
  &_error {
    color: $primaryRed;
  }
}
