// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

$buttonsColumnWidth: 96px;

.Header {
  clip-path: polygon(38% 0px, 100% 0px, 100% 100%, 35% 100%);
  border-bottom: 1px solid $darkRed;
  margin: $mediumSpace;
  padding: 4px 16px 0;
  border-bottom: 2px solid $darkRed;
  background-color: $primaryYellow;
  &_title {
    text-align: right;
    font-size: $mediumFontSize;
    margin-top: $mediumSpace;
    font-weight: $mediumFontWeight;
    background-color: $primaryYellow;
  }
}

.ReportPage {
  &__main {
    display: grid;
    grid-template-areas:
      'title'
      'filters';
    @include paddingMain;
  }

  &__title {
    grid-area: title;

    @include ListMarginBottom;

    &_header {
      padding-left: $mediumSpace;
    }
  }

  &__filters {
    grid-area: filters;

    display: grid;

    @include respond-to($phone-media) {
      grid-row-gap: $smallSpace;
      grid-template-areas: 'SelectFiltersSection' 'ActiveFiltersSection';
    }

    @include respond-to($tablet-media, $desktop-media) {
      grid-column-gap: $mediumSpace;
      grid-template-columns: 50% 50%;
      grid-template-areas: 'SelectFiltersSection ActiveFiltersSection';
      width: calc(100% - #{$mediumSpace});
    }

    @include ListMarginBottom;
  }
}
