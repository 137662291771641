@import '../../theme/mixins.scss';

.LoadingWheel {
  // Loading on hover for full width loader.
  &__fullWidth {
    position: fixed;
    background-color: $standardGrey;
    z-index: 1000;
    opacity: 0.4;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to($desktop-media) {
      top: 0;
      left: $navBar-size-lg;
      width: calc(100vw - #{$navBar-size-lg});
      height: 100%;
    }

    @include respond-to($tablet-media, $phone-media) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
