@import '../../theme/index.scss';
@import '../../theme/mixins.scss';

.AutoComplete {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &_textarea {
    flex: 4;
    display: flex;
  }
  &_clearbutton {
    flex: 1;
  }
  &_loadingContainer {
    width: 24px;
    padding: 30px $smallSpace;
  }
  &_editButton {
    border-radius: 20px !important;
    padding: 0 $mediumSpace !important;
    background-color: $veryLightGrey !important;
    margin: 0 $mediumSpace !important;
    &_icon {
      width: 20px !important;
    }
  }
}
// Design for AutoForms
.Form .AutoComplete {
  @include autoFormField;
  margin-top: -16px;

  justify-content: left;
  &_textarea {
    flex: none;
  }
  &_clearbutton {
    flex: none;
  }
}
