@import '../../../../../theme/index.scss';
@import '../../../../../theme/media.scss';

.CRMNoteField {
  display: flex;
  margin: $largeSpace 0;
  flex-wrap: wrap;
  flex-direction: column;
  &_textarea {
    flex: 1;
    padding: $smallSpace;
    resize: none;
    border: 2px solid $lightGrey;
    outline: none;
    max-width: 400px;
  }
  &_button {
    background-color: $primaryYellow !important;
    padding: 0 $smallSpace !important;
    margin: $smallSpace $smallSpace 0 0 !important;
    &_cancel {
      @extend .CRMNoteField_button;
      background-color: $lightGrey !important;
    }
    &_text {
      margin: 0 $smallSpace;
    }
    &_container {
      display: flex;
      align-items: flex-end;
    }
  }
}
