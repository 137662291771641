/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../theme/index.scss';

.ClearButton {
  align-items: center;
  background-color: $veryLightGrey !important;
  border-radius: 50%;
  display: inline-flex !important;
  justify-content: center;
  padding: 0 !important;

  height: $largeSpace !important;
  width: $largeSpace !important;

  > div > svg {
    height: $mediumSpace !important;
    width: $mediumSpace !important;
  }
}
