@import '../../theme/index.scss';

.AdvertReport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_title {
    font-size: 2em;
  }
  &_subTitle {
    font-size: 1.5em;
    margin: 0;
    text-transform: capitalize;
  }
  &_statContainer {
    flex: 1;
    text-align: center;
    padding: 0 40px 40px 40px;
  }
  &_stat {
    font-size: 3em;
    font-weight: 800;
    margin: 0;
  }
  &_section {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  &_select {
    margin-bottom: 10px;
  }
  &_barChart {
    margin-top: 30px;
  }
  &_datesSection {
    display: flex;
    gap: 80px;
    &_section {
      text-align: center;
      &_date {
        padding: 5px;
        border-radius: 15px;
        border: solid 1px;
        font-family: MierB;
      }
    }
    &_error {
      color: $darkRed;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    margin-bottom: 40px;
  }
}
