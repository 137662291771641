@import '../../theme/index.scss';

.TranslationFields {
  border: 2px solid $veryLightGrey;
  margin-bottom: $smallSpace;
  border-radius: $smallSpace;
  &_unsaved {
    @extend .TranslationFields;
    border: 2px solid $primaryRed;
  }
  &_error {
    margin: $smallSpace $mediumSpace;
    color: $primaryRed;
  }
  &_success {
    margin: 0 $mediumSpace;
    color: $darkerGreen;
  }
  &_saveButton {
    background-color: $primaryGreen !important;
    padding: 0 $smallSpace !important;
    margin-left: 16px !important;
  }
  &_input {
    padding: 0 $mediumSpace $mediumSpace $mediumSpace;
    margin-bottom: 0;
  }
  &_countrySelector {
    display: flex;
    flex-wrap: wrap;
    &_item {
      margin: $smallSpace !important;
      border: 2px solid transparent !important;
      border-radius: $smallSpace !important;
      padding: 0 $smallSpace !important;
      & div {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      &_selected {
        @extend .TranslationFields_countrySelector_item;
        border: 2px solid $primaryYellow !important;
      }
      & img {
        max-height: 20px;
        margin: 0 $smallSpace;
      }
      & p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}
