@import '../../theme/index.scss';

.QuizAnswerForQuestion {
  max-width: 600px;
  padding: $smallSpace $mediumSpace;
  background-color: $veryLightGrey;
  margin-bottom: $mediumSpace;
  border-radius: 8px;
  &_error {
    @extend .QuizAnswerForQuestion;
    background-color: rgba(255, 0, 0, 0.6);
  }
  &_createButton {
    background-color: $primaryYellow !important;
    margin: $mediumSpace 0 !important;
    padding: 0 $smallSpace !important;
  }
  &_errorText {
    color: $primaryRed;
  }
}

.EditAnswerField {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: $smallSpace;
  justify-content: space-evenly;
  align-items: center;
  &_button {
    &_container {
      display: flex;
    }
    background-color: $lightGrey !important;
    &_save {
      background-color: $primaryGreen !important;
    }
  }
  &_section {
    flex: 1;
    display: flex;
    align-items: center;
    & svg {
      margin-right: 10px;
    }
  }
}
