// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.OnboardingTile {
  &__wrapper {
    padding: $mediumSpace;
    border-radius: $mediumSpace;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include respond-to($phone-media) {
      flex-direction: column;
    }
    &_content {
      display: flex;
      flex-direction: column;
      flex: 2;
      &_label {
        font-size: $largeFontSize;
        font-weight: $mediumFontWeight;
        color: $lightGrey;
      }
    }
    &_textField {
      @include respond-to($phone-media) {
        margin: $smallSpace;
      }
      width: 95%;
      margin: $mediumSpace 0;
      padding: $mediumSpace 0;
      align-items: center;
      background-color: rgba($white, 0.4);
    }
    &_preview {
      flex: 1;
    }
  }
}
