@import '../../../../../theme/index.scss';
@import '../../../../../theme/media.scss';

.CRMNotes {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid $lightGrey;
  &_searchBar {
    margin: $mediumSpace 0;
  }
  &_row {
    display: flex;
  }
  &_header {
    font-weight: 700;
    max-height: 40px;
    text-transform: capitalize;
  }
  &_arrowContainer {
    display: flex;
    flex-direction: column;
  }
  &_cell {
    background-color: $white;
    margin: 0;
    padding: $smallSpace;
    border: 1px solid $lightGrey;
    font-size: 12px;
    width: 100px;
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    @include respond-to($phone-media) {
      width: 50px;
    }
  }
  &_id {
    @extend .CRMNotes_cell;
    width: 80px;
  }
  &_date {
    @extend .CRMNotes_cell;
    width: 100px;
    @include respond-to($phone-media) {
      width: 60px;
    }
  }

  &_author {
    @extend .CRMNotes_cell;
    justify-content: flex-start;
  }
  &_edit {
    @extend .CRMNotes_cell;
    width: 20px;
  }
  &_message {
    @extend .CRMNotes_cell;
    flex: 1;
  }
}
