@import './theme/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryYellow: #{$primaryYellow};
  --darkYellow: #{$darkYellow};
  --primaryRed: #{$primaryRed};
  --darkRed: #{$darkRed};
  --lightRed: #{$lightRed};
  --primaryGreen: #{$primaryGreen};
  --darkGreen: #{$darkGreen};
  --primaryOrange: #{$primaryOrange};
  --primaryPurple: #{$primaryPurple};
  --primaryLightBlue: #{$primaryLightBlue};
  --black: #{$black};
  --darkGrey: #{$darkGrey};
  --standardGrey: #{$standardGrey};
  --lightGrey: #{$lightGrey};
  --veryLightGrey: #{$veryLightGrey};
  --white: #{$white};
}
