/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */
// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.MultilineTextFieldEdit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  &_left {
    display: inline-flex;
    width: calc(50% - #{$smallSpace});
    &_new {
      &_text {
        width: 300px !important;
        @include respond-to($tablet-media) {
          width: 250px !important;
        }
        @include respond-to($phone-media) {
          width: 200px !important;
        }
        font-size: $smallFontSize;
        > div {
          font-size: $smallFontSize !important;
          word-wrap: break-word;
          overflow-wrap: break-word;
          bottom: $smallSpace !important;
        }
      }
      &_chars {
        font-size: $smallFontSize;
        color: $darkGrey;
      }
    }
    &_info {
      &_wrapper {
        grid-area: info;
        padding-top: $mediumSpace;
        padding-left: $mediumSpace;
      }
    }
    @include respond-to($phone-media) {
      width: 100%;
    }
  }
  &_previous {
    width: 50%;
    h5 {
      font-weight: lighter;
      font-size: 16px;
      color: $standardGrey;
      margin-bottom: $smallSpace;
    }
    &_text {
      word-wrap: break-word;
      overflow-wrap: break-word;
      width: auto !important;
      font-size: $smallFontSize;
    }
    @include respond-to($phone-media) {
      width: 100%;
    }
  }
}
