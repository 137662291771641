// Theme
@import '../../theme/index.scss';
@import '../../theme/media.scss';

.LoginPage {
  &__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &_input_wrapper {
    background-color: white;
    padding: $smallSpace;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-top: $mediumSpace;
    }

    &_inputs {
      display: flex;
      flex-direction: column;
    }

    &_submit {
      &_button {
        font-weight: 700;
      }
    }

    &_reset_password {
      text-align: center;
      padding: $mediumSpace;
    }
  }

  &_alert_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $mediumSpace 0;

    @include respond-to($desktop-media, $tablet-media) {
      max-width: 50%;
    }
  }
}
