@import '../../../theme/index.scss';

.adZone {
  &_buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 16px;
  }

  &_button {
    cursor: pointer;
    padding: 18px;
    background-color: $primaryYellow;
    border: 0;
    font-size: 15px;
    margin-bottom: 16px;
    width: 33%;
  }
  &_deleteButton {
    cursor: pointer;
    padding: 18px;
    background-color: $primaryRed;
    text-transform: uppercase;
  }
  &_expandable {
    width: 100%;
  }
}
