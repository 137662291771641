@import '../../theme/index.scss';
@import '../../theme/media.scss';

.ImportItems {
  padding: $smallSpace;
  display: flex;
  flex-direction: column;
  &_description {
    color: $darkGrey;
    margin: $smallSpace $mediumSpace;
  }
  &_section {
    margin: $mediumSpace;
    &_errors {
      margin: $mediumSpace;
      border-radius: $smallSpace;
      padding: $mediumSpace;
      border: 1px solid $primaryRed;
    }
  }
  &_textError {
    color: $primaryRed;
  }
  &_uploader {
    min-width: 250px;
    display: flex;
    margin: auto;
    flex-direction: column;
    &_buttons {
      background-color: $veryLightGrey;
      margin: $mediumSpace;
      border-radius: $smallSpace;
      &_innerContainer {
        padding: $mediumSpace;
      }
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap-reverse;
      padding: $largeSpace;
      &_button {
        &_help {
          max-width: 300px;
          color: $darkGrey;
        }
      }
    }
    &_clearEmpty {
      max-width: 300px;
      padding: $mediumSpace;
      &_label {
        margin: 0;
        width: 150px;
        display: flex;
        align-items: center;
      }
      &_toggle {
        // max-width: 150px;
        margin: auto;
        display: flex;
      }
    }
    &_container {
      flex: 1;
      padding: $largeSpace;
      flex-wrap: wrap;
      display: flex;
    }
  }
}
