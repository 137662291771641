// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.InAndOut {
  @include scrollbar(0.5em, $darkYellow);
  @include respond-to($desktop-media, $tablet-media) {
    width: 45%;
  }

  @include respond-to($phone-media) {
    width: 80%;
    margin: auto;
    margin-bottom: $mediumSpace;
  }

  border: 2px solid $lightGrey;
  background-color: rgba($veryLightGrey, 0.3);
  &_header {
    background-color: $veryLightGrey;
    height: calc(#{$largeSpace} * 2);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
    margin-bottom: $smallSpace;
    font-size: $mediumFontSize;
    border-bottom: 2px solid $darkGrey;
    &_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: $mediumSpace $smallSpace 0 0;
      svg {
        padding-bottom: $smallSpace;
        margin-right: $smallSpace;
      }
      span {
        font-weight: $mediumFontWeight;
      }
    }
  }

  &_list {
    height: calc(#{$largeSpace} * 16);
    overflow: auto;
    &_item {
      background-color: rgba($lightGrey, 0.3) !important;
      border-bottom: 2px solid $lightGrey !important;
      font-size: $mediumFontSize !important;
      font-weight: $mediumFontWeight !important;
      transition: ease-out 3s;
      div {
        svg {
          fill: $darkGrey !important;
        }
        div {
          padding-top: calc(#{$smallSpace} - 4px);
        }
      }
    }
    &_item:hover {
      border-bottom: 2px solid $primaryYellow !important;
      background-color: rgba($lightGrey, 0.5) !important;
    }
  }

  &__nestedList_add,
  &__nestedList_remove {
    border-bottom: 1px solid $lightGrey !important;
    div {
      margin-left: 0 !important;
      font-size: 0.95em;
      div {
        svg {
          fill: $darkGrey !important;
        }
        div:first-of-type {
          background-color: $white !important;
          border: 1px solid $lightGrey;
          color: $darkGrey !important;
          font-size: $smallSpace !important;
        }
      }
    }

    &_text {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: calc(#{$largeSpace} * 2);
        margin-left: $mediumSpace;
      }
    }
  }

  &__nestedList_add:hover,
  &__nestedList_remove:hover {
    border-bottom: 2px solid $primaryYellow !important;
  }

  &_loadMore {
    margin: $smallSpace;
    color: $white;
    justify-content: center;
    display: flex !important;
    button {
      background-color: $lightGrey !important;
      padding: 4px !important;
      color: $white !important;
      &:hover {
        background-color: rgba($darkGrey, 0.5) !important;
      }
    }
  }

  &_footer {
    background-color: $veryLightGrey;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
    padding: $smallSpace;

    border-top: 2px solid $darkGrey;
    &_search {
      box-shadow: none !important;
      border-radius: $largeSpace !important;
      width: 80%;
      border: 2px solid $lightGrey;
    }
  }
}
