@import '../../theme/index.scss';

@import '../../theme/mixins.scss';

.HomepageApps {
  display: flex;
  flex-direction: column;
  &_title {
    align-self: flex-start;
    margin: 0;
  }
  &_columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &_countrySelect {
    border: 1px solid $primaryYellow !important;
    padding: 0 $mediumSpace !important;
    border-radius: $verySmallSpace !important;
    align-self: center;
    &_container {
      padding: $mediumSpace;
      display: flex;
      flex-direction: column;
    }
  }

  @include respond-to($desktop-media) {
    padding: $mediumSpace;
  }

  @include respond-to($tablet-media) {
    padding: $smallSpace;
  }

  @include respond-to($phone-media) {
    padding: $smallSpace 0;
  }

  &__error {
    @include respond-to($desktop-media) {
      margin: 0 $smallSpace $mediumSpace;
    }

    @include respond-to($tablet-media) {
      margin: 0 $verySmallSpace $smallSpace;
    }

    @include respond-to($phone-media) {
      margin: 0 0 $smallSpace;
    }
  }
}
