/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../../theme/index.scss';
// Media
@import '../../../theme/media.scss';
// Mixins
@import '../../../theme/mixins.scss';

.TextField {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $mediumSpace calc(#{$mediumSpace} - 12px) $mediumSpace $mediumSpace;

  @include formFieldContainer;

  &_content {
    &_name {
      font-size: $mediumFontSize;
      margin-bottom: $smallSpace;
      color: $darkGrey;
      font-weight: $lightFontWeight;
    }

    &_text {
      font-size: $mediumFontSize;
      color: $black;
    }
  }
}
