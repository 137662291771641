@import '../../theme/index.scss';
@import '../../theme/mixins.scss';

$listWidth_desktop: 350px;
$listWidth_tablet: 350px;

.DragAndDrop__Context {
  display: flex;
  justify-content: center;
  padding: 2 * $borderWidth 0;
}

.DragAndDrop__List {
  background-color: $white;

  @include CardAspect;

  &.active {
    background-color: $veryLightGrey;
  }

  &_container {
    display: inline-block;
    vertical-align: top;

    @include respond-to($desktop-media) {
      margin: 0 $smallSpace;
    }

    @include respond-to($tablet-media) {
      margin: 0 $verySmallSpace;
    }

    @include respond-to($phone-media) {
      margin: 0 $verySmallSpace;
    }

    @include respond-to($desktop-media) {
      width: $listWidth_desktop;
    }

    @include respond-to($tablet-media) {
      width: $listWidth_tablet;
    }
  }

  &_content {
    @include paddingMain;
  }
}
