/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 30/07/2018
 */
// Theme.
@import '../../theme/index.scss';

.TableHeaderColumnWithSort {
  &__name {
    display: inline-block;
    position: relative;
    top: $verySmallSpace;
  }

  &__sort {
    &_wrapper {
      // Force the two sort symbols to align vertically.
      display: inline-flex;
      flex-wrap: wrap;
      width: 20px;
    }

    &_asc {
      bottom: -6px;
    }

    &_desc {
      top: -6px;
    }

    &_asc,
    &_desc {
      cursor: pointer;
      position: relative;
    }
  }
}
