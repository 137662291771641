// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 999;
}

.content {
  background-color: white;
  padding: $smallSpace;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  &_header {
    margin-top: -60px;
    display: flex;
    justify-content: center;
    &_avatar {
      border: 2px solid $darkRed;
      svg {
        fill: $darkRed !important;
      }
    }
  }
  &_buttons {
    display: flex;
    justify-content: space-evenly;
  }
  &_errorText {
    &_divider {
      background-color: $lightGrey !important;
      width: 80%;
      margin: auto !important;
    }
    &_message {
      color: $darkRed;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
