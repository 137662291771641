/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../../theme/index.scss';
// Media
@import '../../../theme/media.scss';
// Mixins
@import '../../../theme/mixins.scss';

.NumberEdit {
  &__item {
    // The info icon has itself a 12px padding
    padding: $smallSpace calc(#{$mediumSpace} - 12px) $smallSpace $mediumSpace;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    @include formFieldContainer;

    &_content {
      display: grid;
      grid-template-rows: $mediumSpace auto;
      grid-template-areas:
        'text'
        'value';
      &_text {
        grid-area: text;
      }

      &_value {
        grid-area: value;

        @include respond-to($phone-media) {
          max-width: 172px;
        }

        > input {
          font-size: $smallFontSize !important;
        }

        > div {
          font-size: $smallFontSize !important;
          bottom: $verySmallSpace !important;

          > hr {
            max-width: $largeSpace !important;
          }
        }
      }
    }

    &_info {
      @include tooltipLightGrey;
    }
  }
}
