/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 16/11/2018
 */

@import '../../../theme/index.scss';

.OnboardingTile {
  &__wrap {
    &_preview {
      &_title {
        font-size: $intermediateFontSize;
        font-weight: $mediumFontWeight;
        color: $standardGrey;
      }
      &_content {
        height: 356px;
        width: 256px;
        border: 2px solid $lightGrey;
        border-radius: 2px;
        background: #ffffff;
        overflow-y: hidden;
        &_pic {
          width: 100%;
          height: 144px;
          object-fit: contain;
        }
        &_text {
          margin: $smallSpace $mediumSpace;
          font-size: $intermediateFontSize;
          text-align: center;
          height: 40%;
          &_title {
            font-weight: $strongFontWeight;
            padding: $smallSpace $mediumSpace;
          }
          &_description {
            padding: $smallSpace;
            font-size: $smallFontSize;
            line-height: $largeFontSize;
            color: $standardGrey;
          }
        }
        &_arrow {
          width: 100%;
          div {
            display: flex !important;
            margin: auto;
            background-color: $primaryYellow !important;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
