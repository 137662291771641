@import '../../../theme/index.scss';

.TimeField {
  &_wrapper {
    margin-top: 40px;
  }

  &_timeContainer {
    display: flex;
    align-items: center;
  }

  &__time,
  &__cancel {
    font-size: $smallFontSize !important;
  }

  &__time {
    div > hr {
      border: none !important;
    }
  }

  &__cancel {
    height: $mediumSpace !important;
    width: $mediumSpace !important;

    > div > svg {
      height: $smallSpace !important;
      width: $smallSpace !important;
    }
  }
}
