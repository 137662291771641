/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../../theme/index.scss';
// Media
@import '../../../theme/media.scss';
// Mixins
@import '../../../theme/mixins.scss';

.BooleanField {
  @include autoFormField;

  display: flex;
  flex-direction: row;
  align-items: baseline;

  &_header {
    label {
      width: auto !important;
    }
    &_avatar {
      margin-right: $smallSpace !important;
      border: 1px solid $standardGrey;
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
  }

  &_info {
    @include tooltipLightGrey;
  }
}
