@import '../../theme/index.scss';
@import '../../theme/mixins.scss';

.HomepageSection {
  margin-bottom: $smallSpace;
  padding: $smallSpace;
  user-select: none;
  @include CardAspect;

  &.unused {
    background-color: $standardGrey;
  }
}
