// Theme
@import 'index.scss';
// Media
@import './media.scss';

// Suppress click animation in list items.
@mixin clickAnimationSuppressor {
  > div > span {
    cursor: auto !important;
    > div > span {
      display: none !important;
    }
  }
}

// Suppress top padding of cards with list.
@mixin cardListTopPaddingSuppressor {
  > div > div {
    padding: 0 0 $smallSpace 0 !important;
  }
}

@mixin paddingMain {
  @include respond-to($phone-media) {
    padding: $smallSpace;
  }

  @include respond-to($desktop-media, $tablet-media) {
    padding: $mediumSpace;
  }
}

@mixin paddingMainEdit {
  @include respond-to($phone-media) {
    padding: $smallSpace $smallSpace calc(#{$smallSpace} + 56px) $smallSpace;
  }

  @include respond-to($desktop-media, $tablet-media) {
    padding: $mediumSpace $mediumSpace calc(#{$mediumSpace} + 56px) $mediumSpace;
  }
}

// Prevent overflow of the inline block inside Title card.
@mixin overflowTitleMobile {
  @include respond-to($phone-media) {
    overflow: hidden;
  }
}

// Allow tooltip wrap to enhance readability.
@mixin tooltipWrap {
  > div > div {
    width: 256px;
    > span {
      white-space: normal !important;
    }
  }
}

// Give a tooltip the lightGrey color.
@mixin tooltipLightGrey {
  > div > svg {
    color: $lightGrey !important;
  }
}

// Live/Paused badge on headers of pages.
@mixin statusBadge {
  position: absolute;
  right: $mediumSpace;
  top: 30%;

  cursor: default;

  padding: $smallSpace;
  line-height: $mediumSpace;
  border-radius: 2px;

  color: $white;
  border: 1px solid;
}

// Gives to a div a Card aspect (useful when using Material UI Card is not possible).
@mixin CardAspect() {
  box-sizing: border-box;
  box-shadow:
    $lightGrey 0 1px 6px,
    $lightGrey 0 1px 4px;
  border-radius: 2px;
}

// Set bottom padding for list elements (filters, search, list etc).
@mixin ListMarginBottom {
  @include respond-to($phone-media) {
    margin-bottom: $smallSpace;
  }

  @include respond-to($tablet-media, $desktop-media) {
    margin-bottom: $mediumSpace;
  }
}

@mixin basicCellForList {
  text-align: center !important;
  padding-left: $smallSpace !important;
  padding-right: $smallSpace !important;
}

$iconSizeForListButton: 48px;

@mixin buttonsForListElement {
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include respond-to($phone-media, $tablet-media) {
    width: $iconSizeForListButton;
  }

  @include respond-to($desktop-media) {
    width: 2 * $iconSizeForListButton;
  }
}

@mixin formFieldContainer {
  @include respond-to($desktop-media) {
    &:hover {
      background-color: $backgroundColor;
    }
  }
}

// TO be used for all auto fields
@mixin autoFormField {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  padding: $smallSpace;
  margin: 0;
}

@mixin greyContainer {
  border-radius: $borderRadius;
  background-color: $veryLightGrey;
}

//scrollbar. Doesn't work in firefox

@mixin scrollbar($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
