@import '../../theme/index.scss';
@import '../../theme/media.scss';

.InAndOutSelector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: $smallSpace 0;
  &_outer {
    border: 1px solid transparent;
    padding-top: 30px;
    &_highlight {
      border: 1px solid $primaryRed;
      border-radius: 10px;
    }
  }
  &_warning {
    text-align: center;
    color: $primaryRed;
  }
  &_button {
    width: 120px;
    &_container {
      margin: $mediumSpace;
      display: flex;
    }
  }
}

.inOutCSVUpload {
  display: flex;
  justify-content: space-between;
  flex: 1;
  &_wrapper {
    display: flex;
  }

  &_csv {
    background-color: $primaryGreen !important;
    border-radius: 2px 0 0 2px !important;
    margin: 0 !important;

    &_input {
      display: none;
    }
  }

  &_help {
    &_csv {
      background-color: $darkGreen !important;
      width: 52px;
      min-width: unset !important;
      border-radius: 0 2px 2px 0 !important;
      border-left: 1px $black solid !important;
    }

    &_content {
      &_wrapper {
        display: grid;
        grid-template-columns: auto 45%;
        grid-column-gap: $mediumSpace;
        grid-template-areas: 'text img';
      }
      &_img {
        grid-area: img;
        object-fit: contain;
        width: 100%;
      }
    }
  }
}
