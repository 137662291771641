// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.PasswordReset {
  &_main {
    > div > div > div {
      max-width: 70%;
      margin: auto;
    }
    h3 {
      border-bottom: 1px solid $standardGrey;
      font-size: $largeFontSize !important;
      font-weight: $lightFontWeight !important;
      color: $standardGrey !important;
      margin-bottom: $largeSpace !important;
    }
    &_background {
      color: $darkGrey;
      position: fixed;
      margin-left: 40%;
      opacity: 0.075;
      svg {
        height: 148px !important;
        width: 148px !important;
      }
    }
    &_inputs {
      margin-top: $largeSpace !important;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: auto;
      #ResetPassword {
        margin-bottom: $mediumSpace;
      }
      &_success {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 130px;
        justify-content: center;
        span {
          color: $primaryGreen;
          font-size: $smallFontSize;
          font-weight: $mediumFontWeight;
          margin-bottom: $largeSpace;
        }
        svg {
          width: 60px !important;
          height: 60px !important;
        }
      }
    }
    @include respond-to($phone-media) {
      > div > div {
        width: 95% !important;
        > div {
          max-width: 95%;
          margin: auto;
        }
      }
      h3 {
        font-size: $mediumFontSize !important;
        margin-bottom: $mediumSpace !important;
      }
      &_background {
        svg {
          height: 58px !important;
          width: 58px !important;
        }
      }
    }
  }
}
