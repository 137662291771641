/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';

.ExpendableCard {
  margin-bottom: $mediumSpace !important;

  @include respond-to($phone-media) {
    // Remove bottom padding on phones.
    > div {
      padding: 0 !important;
    }
  }

  &__header {
    background-color: $primaryYellow !important;

    display: flex;
    align-items: center;

    > div {
      padding-right: $smallSpace !important;
    }

    &_chip {
      border-radius: 2px !important;
      background-color: $lightGrey !important;
      display: inline-block !important;
      margin-bottom: 4px !important;

      > span {
        line-height: 0 !important;
        padding: 10px 4px 8px 4px !important;
        font-size: $smallFontSize !important;
      }
    }
  }
}
