@import '../../theme/index.scss';

.SideBarItem {
  &_link {
    display: flex;
    padding: $smallSpace;
    &_active {
      @extend .SideBarItem_link;
      background-color: rgba($primaryYellow, 0.4) !important;
    }
    &_title {
      padding-left: $mediumSpace;
      color: white;
      margin: 0;
      text-transform: capitalize;
    }
    &:hover {
      background-color: rgba($primaryYellow, 0.2) !important;
    }
    &_first {
      flex: 4;
    }
    &_nested {
      flex: 4;
      transform: scale(0.8);
    }
  }
}
