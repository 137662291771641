@import '../../theme/index.scss';
@import '../../theme/mixins.scss';
@import '../../theme/media';

.URLShortener {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond-to($phone-media) {
    width: 100%;
  }
  &_container {
    align-self: center;
    width: 80%;
    margin: 28px;
    padding: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow:
      rgba(0, 0, 0, 0.12) 0px 1px 6px,
      rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 2px;
    @include respond-to($phone-media) {
      width: 95%;
      padding: 10px;
    }
    &_info {
      margin-bottom: 0;
    }
    &_inputBox {
      width: 70%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include respond-to($phone-media) {
        width: 100%;
      }
      &_customizeBox {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
    }
  }
  &_urlWrapper {
    width: 90%;
    @include respond-to($phone-media) {
      width: 100%;
    }
  }
}

.UrlTab {
  border: 1px solid $veryLightGrey;
  border-radius: 2px;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 2px,
    rgba(0, 0, 0, 0.12) 0px 1px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  margin: 10px 0;
  box-sizing: border-box;
  &_linksBox {
    padding: 6px;
    width: 85%;
    @include respond-to($phone-media) {
      width: 75%;
    }
    &_short {
      display: flex;
      justify-content: left;
      &_link {
        padding: 10px;
        border: none;
        font-size: 30px;
        resize: none;
        @include respond-to($phone-media) {
          font-size: 18px;
        }
      }
    }
    &_orginal {
      margin: 5px;
      font-size: 15px;
      color: $standardGrey;
      word-wrap: break-word;
      @include respond-to($phone-media) {
        font-size: 11px;
      }
    }
  }
}
