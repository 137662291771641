@import '../../theme/index.scss';
@import '../../theme/media.scss';

.TopFixturesList {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 16px;
  @include respond-to($phone-media) {
    margin: 16px 0;
  }
  &_hideOnMobile {
    @include respond-to($phone-media) {
      display: none !important;
    }
  }
  &_header {
    @extend .TopFixturesList_dnd_item_row;
    background-color: $primaryYellow !important;
    border: 1px solid transparent !important;
    @include respond-to($desktop-media, $tablet-media) {
      padding-left: 80px !important;
    }
  }
  &_dnd {
    &_icon {
      cursor: pointer;
      align-self: center;
      display: flex;
      align-items: center;
      padding: 0px;
      &_drag {
        @extend .TopFixturesList_dnd_icon;
        cursor: grab;
        & img {
          max-height: 25px;
        }
      }
    }
    &_item {
      width: 70px;
      display: flex;
      padding: 0 $smallSpace;
      &_longer {
        @extend .TopFixturesList_dnd_item;
        flex: 1;
      }
      &_row {
        font-size: 1em;
        display: flex;
        padding: 0 10px 0 10px;
        border-bottom: 1px solid grey;
        background-color: $white;
      }
    }
  }
  &_button {
    background-color: $primaryYellow !important;
    margin: $mediumSpace auto 0 !important;
    width: 200px;
    &_container {
      margin: $mediumSpace 0;
      padding: $mediumSpace;
      display: flex;
      justify-content: space-between;
    }
  }
  &_error {
    @extend .TopFixturesList;
    border: 2px solid $primaryRed;
    border-radius: 8px;
    &_message {
      text-align: center;
      color: $primaryRed;
    }
  }
  &_addButton {
    @extend .TopFixturesList_button;
    background-color: $primaryGreen !important;
    & div {
      display: flex !important;
      justify-content: space-evenly !important;
      align-items: center !important;
    }
  }
  &_selector {
    background-color: rgba(200, 200, 200, 0.2);
    &_title {
      padding: $mediumSpace 0 0 0;
      text-align: center;
    }
  }
}

.FixtureList {
  display: flex;
  flex-direction: column;
  padding: $smallSpace;
  overflow: scroll !important;
  max-height: 250px;
  &_dialog {
    // overriding the 75% width in <Dialog /> applied by mui
    @include respond-to($phone-media) {
      & div {
        width: 100% !important;
        & div {
          width: auto !important;
        }
      }
    }
  }
  &_itemRow {
    display: flex;
    justify-content: center;
    margin: $smallSpace 0;
  }
  &_button {
    background-color: $primaryYellow !important;
    width: 140px;
  }
  &_searchBar {
    flex: 1;
  }
  &_close {
    text-align: right;
  }
}
