@import '../../theme/index.scss';

.ReactivePrize {
  &_container {
    margin: 20px;
  }
  &_successText {
    color: $primaryGreen;
  }
  &_errorText {
    color: $primaryRed;
  }
}
