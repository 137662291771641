/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../../theme/index.scss';
// Media
@import '../../../theme/media.scss';
// Mixins
@import '../../../theme/mixins.scss';

.TextField {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $smallSpace;
    justify-content: space-between;
    padding: $smallSpace;

    &_content {
      display: flex;
      flex-direction: column;
      width: 100%;

      span {
        color: $standardGrey;
        font-size: $mediumFontSize;
      }
      &_value {
        color: $darkGrey;
        font-size: $mediumFontSize;
      }
      &_chars {
        color: $standardGrey;
        font-size: $smallFontSize;
      }
    }
  }
}
