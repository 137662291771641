/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

// Theme
@import '../../theme/index.scss';

.MpSectionHeader {
  color: $black !important;
  background-color: $primaryYellow !important;
}
