// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

$curve: cubic-bezier(0.65, 0, 0.45, 1);

.AnimatedCheckbox {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  display: block;
  stroke-width: 4px;
  stroke: #fff;
  stroke-miterlimit: 20;
  box-shadow: inset 0 0 0 $primaryGreen;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;

  &__circle {
    stroke-dasharray: 366;
    stroke-dashoffset: 366px;
    stroke-width: 2px;
    stroke-miterlimit: 10;
    stroke: $primaryGreen;
    fill: none;
    animation: stroke 0.3s $curve forwards;
  }

  &__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 96;
    stroke-dashoffset: 96px;
    animation: stroke 0.3s $curve 0.8s forwards;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 60px $primaryGreen;
  }
}
