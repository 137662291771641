// Theme
@import '../../../../theme/index.scss';
// Media
@import '../../../../theme/media.scss';
// Mixins
@import '../../../../theme/mixins.scss';

$buttonsColumnWidth: 144px;

.ListPage {
  &__main {
    display: grid;
    grid-template-areas:
      'title'
      'search'
      'filters'
      'error'
      'list'
      'load_more';
    @include paddingMain;
  }

  &__title {
    @apply my-4 text-2xl font-semibold;
    grid-area: title;
  }

  &__search {
    grid-area: search;

    @include ListMarginBottom;
  }

  &__filters {
    grid-area: filters;

    @include ListMarginBottom;
  }

  &__error {
    grid-area: error;

    @include ListMarginBottom;
  }

  &__list {
    grid-area: list;

    @include ListMarginBottom;

    &_header {
      background-color: $primaryYellow;
      color: $black !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_export_container {
        height: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 200px;
      }
      &_button {
        & div {
          & div {
            padding: 0 $mediumSpace !important;
          }
        }
      }

      &_date_icon {
        font-size: $smallFontSize !important;
        margin-right: $smallSpace;
      }
    }

    &_row {
      width: 100%;

      &_buttons {
        display: inline-block;
        height: 100%;

        &_container {
          display: flex;
          justify-content: flex-end;
        }

        &_cell {
          padding: 0 !important;
          width: $buttonsColumnWidth;
        }
      }

      &_cell_link {
        align-items: center;
        color: inherit;
        display: flex;
        text-decoration: inherit;
      }
    }

    &_footer {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 0 $smallSpace;
      width: 100%;

      &_loader {
        padding: $mediumSpace 0 $mediumSpace 0;
      }
    }
  }

  &__load_more {
    display: flex;
    grid-area: load_more;
    justify-content: center;
  }
}
