@import '../../theme/index.scss';

.MandatePage {
  display: flex;
  flex-wrap: wrap;
  &_field {
    flex: 1;
    min-width: 300px;
    margin: $smallSpace;
    text-transform: capitalize;
  }
  &_warning {
    color: $primaryOrange;
  }
}
