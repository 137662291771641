/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|  MP |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */

// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.DatePickerEdit {
  &__item {
    // The info icon has itself a 12px padding
    padding: $smallSpace calc(#{$mediumSpace} - 12px) $smallSpace $mediumSpace;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    &_content {
      display: flex;
      flex-direction: column;
      color: $standardGrey;
      &_picker {
        grid-area: picker;
        display: grid;
        grid-template-columns: 94px $largeSpace auto;
        grid-template-areas: 'date cancel error';
        align-items: center;

        &_date {
          grid-area: date;
          > div {
            width: 94px !important;
          }

          > div > input {
            font-size: $smallFontSize !important;
          }

          > div > div {
            font-size: $mediumFontSize;
          }
        }

        &_error {
          grid-area: error;
          margin-left: $smallSpace;
          font-size: $smallFontSize;
          color: $primaryRed;
        }

        &_cancel {
          margin-left: $smallSpace;
          grid-area: cancel;
        }
      }
    }

    &_info {
      @include tooltipLightGrey;
    }
  }
}

// Design for AutoForms
.Form .DatePickerEdit__item {
  @include autoFormField;
}
