/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 26/07/2018
 */
// Theme
@import '../../theme/index.scss';
// Mixins
@import '../../theme/mixins.scss';

.ColorPickerWithPreview {
  &__wrapper {
    display: grid;
    grid-template-rows: $largeSpace auto;
    grid-template-areas:
      'title .'
      'picker preview';
    grid-column-gap: $mediumSpace;
    grid-row-gap: $smallSpace;

    padding: $mediumSpace;
  }

  &__title {
    grid-area: title;
    display: flex;
    align-items: center;

    &_text {
      font-size: $intermediateFontSize;
    }

    &_info {
      @include tooltipLightGrey;
    }
  }

  &__picker {
    grid-area: picker;
  }

  &__preview {
    &_wrapper {
      grid-area: preview;

      display: flex;
      justify-content: center;
    }

    @include respond-to($phone-media) {
      width: 56px;
    }
    @include respond-to($desktop-media, $tablet-media) {
      width: 256px;
    }
    height: 100%;
    border-radius: 2px;
    border: 2px solid $lightGrey;
  }
}
