@import '../../theme/mixins.scss';

.Enum {
  &_textarea {
    display: inline-block;
    & > div {
      height: unset !important;
      & > label {
        top: 18px !important;
      }
      & > div {
        margin-top: unset !important;
      }
    }
  }
  &_clearbutton {
    margin-left: 20px;
    margin-top: 15px;
    vertical-align: top;
    display: inline-block;
  }
}

// Design for AutoForms
.Form .Enum {
  @include autoFormField;
}
