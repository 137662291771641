// Theme
@import '../../../theme/index.scss';
// Mixins
@import '../../../theme/mixins.scss';

.DateTime {
  padding: 8px;
  &__Title {
    font-weight: $lightFontWeight;
    color: $standardGrey;
    font-size: $mediumFontSize;
  }
  &__Field {
    display: flex;
  }
}
