@import '../../../theme/index.scss';
@import '../../../theme/media.scss';
@import '../../../theme/mixins.scss';

$avatarRadius: 40;
$logoRadius: 64;
$sideBarBackgroundColor: $black;

$headerHeight_desktop: 48px;
$headerHeight_phone: 112px;

.menu_button {
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  &_arrow {
    width: 20px;
    height: 20px;
    margin: auto;
    & svg {
      width: 100%;
      height: 100%;
    }
    &_open {
      @extend .menu_button_arrow;
      transform: rotate(180deg);
    }
  }
}

:export {
  avatarRadius: $avatarRadius;
  backgroundColor: $sideBarBackgroundColor;
  linksFontSize: $mediumFontSize;
  linksTextColor: $lightGrey;
}
