// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';

.SaveBar {
  @apply fixed bottom-0 z-10 flex items-center rounded-t bg-lightGrey px-6 py-4;

  @include respond-to($desktop-media, $tablet-media) {
    left: calc(50vw - 272px);
  }
  @include respond-to($phone-media) {
    left: 0;
  }

  @include respond-to($desktop-media, $tablet-media) {
    width: 512px;
  }
  @include respond-to($phone-media) {
    width: calc(100vw - 32px);
  }

  @include respond-to($desktop-media) {
    justify-content: space-between;
  }

  @include respond-to($phone-media, $tablet-media) {
    justify-content: center;
  }

  &__message {
    font-size: $smallFontSize;
    max-width: 256px;

    @include respond-to($phone-media, $tablet-media) {
      margin-right: $mediumSpace;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: $mediumSpace;
    grid-template-areas: 'dismiss save';

    &_dismiss {
      grid-area: dismiss;
      color: 'white';
    }

    &_save {
      grid-area: save;
      color: 'black';
    }
  }
}

.SuccessSnackBar {
  > div {
    opacity: 0.95;
    background-color: $primaryGreen !important;
    border-radius: 2px 2px 0 0 !important;
  }
}
