@import '../../theme/index.scss';
@import '../../theme/media.scss';

.MatchPintCountrySelector {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $mediumSpace 0 $mediumSpace;
  @include respond-to($phone-media) {
    flex-direction: column;
  }
  &_legend {
    padding: 0 $mediumSpace $mediumSpace $mediumSpace;
  }
  &_error {
    text-align: center;
    color: $primaryRed;
  }
  &_item {
    margin: $smallSpace !important;
    border: 2px solid transparent !important;
    border-radius: 8px !important;
    padding: 0 $mediumSpace !important;
    & div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    &_selected {
      @extend .MatchPintCountrySelector_item;
      border: 2px solid $primaryYellow !important;
    }
    & img {
      max-height: 20px;
      margin: 0 $smallSpace;
    }
    & p {
      margin: 0;
    }
  }
}
