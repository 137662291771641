@import '../../theme/index.scss';
@import '../../theme/media.scss';

$loadingIconHeight: 7px;
$loadingIconColor: $black;

.Loading_Page {
  background-color: $primaryYellow;
  height: 100vh;
  display: grid;
  grid-template-areas:
    '.'
    'loading_banner'
    'loading_icon';
  grid-row-gap: 50px;
  grid-template-rows: 25% auto 15%;
  justify-items: center;

  &__banner {
    grid-area: loading_banner;

    @include respond-to($phone-media) {
      width: 80%;
    }

    @include respond-to($tablet-media) {
      width: 60%;
    }

    @include respond-to($desktop-media) {
      width: 40%;
    }
  }

  &__icon {
    grid-area: loading_icon;
    height: $loadingIconHeight !important;
  }

  &__angle_break {
    position: absolute;
    width: 100px;
    right: 0;
    bottom: 0;
  }
}

:export {
  loadingIconColor: $loadingIconColor;
}
