/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * MatchPint Ltd
 * @author Marwan
 * Date: 19/04/2018
 */
@import '../../theme/index.scss';
@import '../../theme/media.scss';
@import '../../theme/mixins.scss';

$avatarRadius: 40;
$logoRadius: 64;
$sideBarBackgroundColor: $black;

$headerHeight_desktop: 48px;
$headerHeight_phone: 112px;

.NavSideBar {
  @include scrollbar(0.5em, $black);

  &__container {
    width: 100%;

    @include respond-to($desktop-media) {
      // Grid.
      display: grid;
      grid-template-columns: $navBar-size-lg auto;
      grid-template-areas: 'side_bar content_container';
    }
  }

  &__content {
    &_container {
      display: flex;
      flex-direction: column;
      grid-area: content_container;
    }
  }

  &__side_bar {
    z-index: 20;
    overflow-y: auto;
    background-color: $sideBarBackgroundColor;
    height: 100%;
    width: $navBar-size-lg;
    position: fixed;

    &.development {
      background-color: #ff80ab;
    }
    &.staging {
      background-color: #03dac6;
    }

    display: flex;
    flex-direction: column;

    &_separator {
      background-color: $primaryYellow;
      padding: $smallSpace;
    }

    &_top {
      &_logo {
        padding: $smallSpace 1.3em;
        display: flex;
        justify-content: center;
        &_self {
          border-radius: 10px;
          width: #{$logoRadius}px;
          height: #{$logoRadius}px;
          object-fit: contain;
        }
      }
    }

    &_options {
      grid-area: options;
      width: 100%;
      border-top: 1px solid $white;

      &_flat_button {
        height: unset !important;
        width: 100% !important;
        color: $white !important;
        line-height: unset !important;
      }

      > button > div {
        display: flex;
        align-items: center;
        padding: 8px $mediumSpace;
      }

      &_names {
        flex: 1;
        text-align: left;
        padding-left: $mediumSpace;
      }

      &_lastname {
        display: block;
        padding: 2px 0;
        @include respond-to($desktop-media) {
          font-size: $smallFontSize;
        }
        @include respond-to($tablet-media, $phone-media) {
          font-size: $mediumFontSize;
        }
      }

      &_firstname {
        display: block;
        padding: 2px 0;
        @include respond-to($desktop-media) {
          font-size: $smallestFontSize;
        }
        @include respond-to($tablet-media, $phone-media) {
          font-size: $smallFontSize;
        }
      }
    }
  }
}

:export {
  avatarRadius: $avatarRadius;
  backgroundColor: $sideBarBackgroundColor;
  linksFontSize: $mediumFontSize;
  linksTextColor: $lightGrey;
}
