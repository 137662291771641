// Primary Colors.
$primaryYellow: #fed900;
$darkYellow: #c5b900;
$fadedYellow: rgba(254, 217, 0, 0.11);
$lightRed: #ff6d6d;
$primaryRed: #ff0054;
$darkRed: #e9004e;
$primaryGreen: #00f687;
$darkGreen: #00ee83;
$darkerGreen: #00af5f;
$primaryOrange: #f79800;
$primaryPurple: #6904ff;
$primaryLightBlue: #3fb0f0;

// Other colors.
$backgroundColor: #fafafa;

// Black & Greys.
$black: #000000;
$darkGrey: #545454;
$standardGrey: #8a8a8d;
$lightGrey: #dad8d6;
$veryLightGrey: #eeedec;
// White
$white: #ffffff;

$lightFontWeight: 300;
$mediumFontWeight: 500;
$strongFontWeight: 700;
$smallestFontSize: 0.55em;
$smallFontSize: 0.75em;
$mediumFontSize: 1.025em;
$intermediateFontSize: 1.15em;
$largeFontSize: 1.625em;

// Border-radius
$borderRadius: 2px;

// Padding / Margin
$verySmallSpace: 4px;
$smallSpace: 8px;
$mediumSpace: 16px;
$largeSpace: 24px;

// Backgrounds
$dropAsset: url('/assets/upload.png');

// Utils
$navBar-size-lg: 250px;
$borderWidth: 1px;

// Export.
:export {
  primaryYellow: $primaryYellow;
  darkYellow: $darkYellow;
  primaryRed: $primaryRed;
  darkRed: $darkRed;
  lightRed: $lightRed;
  primaryGreen: $primaryGreen;
  darkGreen: $darkGreen;
  primaryOrange: $primaryOrange;
  primaryPurple: $primaryPurple;
  primaryLightBlue: $primaryLightBlue;
  black: $black;
  darkGrey: $darkGrey;
  standardGrey: $standardGrey;
  lightGrey: $lightGrey;
  veryLightGrey: $veryLightGrey;
  white: $white;
}
