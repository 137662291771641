// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';

.resultPage {
  margin: $mediumSpace;
  &__header {
    line-height: $smallSpace;
    color: $standardGrey;
    h1 {
      font-weight: $strongFontWeight;
    }
    p {
      font-size: $smallFontSize;
    }
  }
  &__inputs {
    display: flex;
    justify-content: space-around;
  }
  &__button {
    button {
      border-radius: 0 !important;
      color: $standardGrey !important;
      border: 2px solid $lightGrey !important;
      &:hover {
        border-bottom: 2px solid $primaryYellow !important;
      }
    }
  }
  &__readOnly {
    &_header {
      background-color: $standardGrey;
      border-bottom: 1px solid $primaryYellow;
      clip-path: polygon(0 0, 76% 0, 90% 100%, 0 100%);
      width: 40%;
      font-size: $mediumFontSize;
      font-weight: $mediumFontWeight;
      padding: $mediumSpace $mediumSpace $smallSpace;
      color: $darkGrey;
    }
    &_table {
      display: flex;
      justify-content: center;
      align-items: baseline;

      &_homeTeam {
        &_name {
          padding: $smallSpace $largeSpace $smallSpace $smallSpace;
        }
      }
      &_vs {
        color: $white;
        font-weight: $mediumFontWeight;
        padding: $smallSpace 12px 2px;
        background-color: $lightGrey;
        clip-path: polygon(0 0, 100% 0, 83% 100%, 17% 100%);
      }
      &_awayTeam {
        &_name {
          padding: $smallSpace $smallSpace $smallSpace $largeSpace;
          text-align: right;
        }
      }
      &_homeTeam,
      &_awayTeam {
        &_name {
          font-size: $mediumFontSize;
          color: $standardGrey;
          font-weight: $mediumFontWeight;
        }
        &_score {
          box-sizing: border-box;
          text-align: center;
          border-radius: 8px;
          padding: 16px;
          font-size: 40px;
          color: $standardGrey;
          font-size: 40px;
          border: 2px solid $lightGrey;
        }
      }
    }
  }
}
