@import '../../../../../theme/index.scss';
@import '../../../../../theme/mixins.scss';

.Form {
  padding: $mediumSpace;

  @include respond-to($phone-media) {
    padding: $smallSpace;
  }

  &__error {
    margin-bottom: $mediumSpace;

    @include respond-to($phone-media) {
      padding: $smallSpace;
    }
  }

  &__save_button {
    display: flex;
    justify-content: center;
  }
}
