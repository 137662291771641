@import '../../theme/index.scss';

.ErrorModal {
  &__link {
    color: $primaryRed;
  }

  &__image {
    background: no-repeat center;
    background-size: cover;
    height: 350px;
    margin-top: $mediumSpace;
    width: 100%;
  }
  &__close {
    position: absolute;
    right: $mediumSpace;
    top: $mediumSpace;
    cursor: pointer;
  }
}
