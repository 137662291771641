@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';

.FormAlert {
  @include respond-to($desktop-media, $tablet-media) {
    width: 50% !important;
    margin: auto;
  }
  @include respond-to($phone-media) {
    width: 100% !important;
  }
  background-color: $primaryRed !important;
  top: 0;
  margin: auto;
  border-radius: 0 !important;
  z-index: 10 !important;
  position: relative;

  &__sticky {
    position: sticky !important;
  }

  &__text {
    text-align: center;
    padding: $smallSpace;
    &_header {
      color: $white !important;
      font-weight: $lightFontWeight;
      font-size: $smallFontSize;
    }
    &_subtitle {
      color: $white !important;
      font-size: $smallFontSize;
      text-align: center;
      ul {
        list-style: none !important;
      }
    }
    &_close {
      cursor: auto !important;
      div {
        svg {
          color: $white !important;
        }
      }
    }
  }

  transition-delay: 4s;
}
