@import '../../theme/index.scss';

@import '../../theme/mixins.scss';

.SportSection {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;
  padding: $smallSpace;
  margin-bottom: $smallSpace;

  &__avatar {
    margin-right: $mediumSpace;
  }

  &__text {
    flex: 1;
    font-weight: 500;
  }

  &__priority {
    text-align: center;
    width: 15%;
  }
}
