// Theme
@import '../../theme/index.scss';
@import '../../theme/media.scss';

.ChangePasswordPage {
  &__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &_input_wrapper {
    background-color: white;
    padding: $mediumSpace;
    max-width: 40%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-top: $mediumSpace;
    }

    &_hint,
    &_success {
      margin-top: 0;
      padding: $mediumSpace;
      text-align: center;
    }

    &_success {
      color: $darkGreen;
    }

    &_submit {
      &_button {
        font-weight: 700;
      }
    }
  }

  &_alert_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $mediumSpace 0;

    @include respond-to($desktop-media, $tablet-media) {
      max-width: 50%;
    }
  }
}
