@import '../../../theme/index.scss';
@import '../../../theme/media.scss';

.QuizQuestionImage {
  padding: $mediumSpace;
  max-height: 80px;
}

.QuizQuestionListUnfold {
  // necessary to avoid squeezing due to the table styling of the list view
  min-width: 700px;
  @include respond-to($phone-media) {
    min-width: 350px;
  }
}
