@import '../../theme/index.scss';
@import '../../theme/mixins.scss';
@import '../../theme/media';

.btn {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  &_copy {
    @extend .btn;
    padding-left: 10px;
  }
}

.linkContainer {
  display: flex;
}

.container {
  padding: 0 16px;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
}
.input {
  padding: 8px;
  margin: 8px;
}

.link {
  padding: 6px 0;
  flex: 1;
}
