/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 07/11/2018
 */

// Theme
@import '../../theme/index.scss';
// Media
@import '../../theme/media.scss';
// Mixins
@import '../../theme/mixins.scss';

.ErrorPage {
  max-width: 900px;
  max-height: 600px;
  margin: $largeSpace auto;

  &_background {
    max-width: 100%;
    height: auto;
  }

  &_content {
    margin-left: 250px;
    display: flex;
    flex-direction: column;
    flex: 1;
    &_main {
      width: 400px;
      &_code {
        text-align: end;
        font-size: 6em;
        line-height: 0;
      }
      &_button {
        border: 2px solid $black;
      }
    }

    @include respond-to($tablet-media) {
      margin-left: 110px;
      &_main {
        width: 337px;
        &_code {
          font-size: 4em;
        }
        &_message {
          font-size: $intermediateFontSize;
        }
      }
    }

    @include respond-to($phone-media) {
      margin-left: 30px;
      &_main {
        width: 275px;
        &_code {
          font-size: 2em;
          line-height: 0;
        }
        &_message {
          font-size: $mediumFontSize;
        }
        &_button {
          span {
            font-size: $smallestFontSize !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
