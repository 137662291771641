/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/05/2018
 */
// Theme
@import '../../../theme/index.scss';
// Media
@import '../../../theme/media.scss';
// Mixins
@import '../../../theme/mixins.scss';

.MultilineTextFieldEdit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 $mediumSpace;

  &_left {
    display: inline-flex;
    flex: 1;

    &_new {
      width: 100%;
      padding-right: $mediumSpace;
      &_text {
        font-size: $smallFontSize;
        > div {
          font-size: $smallFontSize !important;
          word-wrap: break-word;
          overflow-wrap: break-word;
          bottom: $smallSpace !important;
        }
      }
      &_chars {
        font-size: $smallFontSize;
        color: $darkGrey;
      }
    }
    &_info {
      &_wrapper {
        grid-area: info;
        padding-top: $mediumSpace;
        padding-left: $mediumSpace;
      }
    }
    @include respond-to($phone-media) {
      width: 100%;
    }
  }
  &_previous {
    flex: 1;

    @include respond-to($phone-media) {
      display: none;
    }

    &_title {
      font-weight: lighter;
      font-size: $smallFontSize;
      color: $standardGrey;
      margin-bottom: $smallSpace;
      margin-top: $mediumSpace;
    }
    &_text {
      font-size: 12px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      width: auto !important;
    }
    @include respond-to($phone-media) {
      width: 100%;
    }
  }
}
