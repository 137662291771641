@import '../../theme/index.scss';
@import '../../theme/media.scss';

$filterWidth: 150px;

.FilterSelect {
  align-items: center;
  display: flex;
  margin-right: $smallSpace;

  &_select {
    max-width: $filterWidth;
  }

  &_info {
    @include respond-to($phone-media, $tablet-media) {
      display: none !important;
    }
  }
}
