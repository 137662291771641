// Theme
@import '../../theme/index.scss';
@import '../../theme/media.scss';

.FileUpload {
  &_uploadFile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: $smallSpace;
    border-radius: $smallSpace;
    border: 2px solid transparent;
  }

  &_rejected {
    border-color: $primaryRed;
    border-style: solid;
  }

  &_label {
    color: $standardGrey;
    font-size: $mediumFontSize;
    padding: $smallSpace;
    &_errorText {
      color: $primaryRed;
    }
  }

  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
  }

  &_dropZone {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    background-color: $veryLightGrey;
    height: 150px;
    width: 280px;
    border-radius: 0;
    text-align: center;
    font-weight: bolder;
    padding: $smallSpace;
    cursor: pointer;
    border: 2px dashed $standardGrey;

    @include respond-to($phone-media) {
      width: 200px;
    }
  }
  &_dropZone:hover {
    opacity: 0.7;
  }

  &_dropText {
    margin-top: 80px;
    font-size: $mediumFontSize;
    text-align: center;
    font-weight: $mediumFontWeight;
  }

  &_videoPlayer {
    padding: 20px;
  }
}
