// Theme
@import '../../theme/index.scss';
@import '../../theme/media.scss';

.PreviewUpload {
  display: flex;
  flex-direction: column;
  margin: $smallSpace;
  &_links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: $smallSpace $largeSpace;
  }
  &_image {
    padding: $smallSpace;
    object-fit: contain;
    width: 300px;
    height: 190px;
    border: 2px solid $lightGrey;
    background-color: rgba($white, 0.5);
    @include respond-to($phone-media) {
      width: 200px;
      margin: 0 auto;
    }
  }
  &_noImage {
    width: 320px;
    margin: $mediumSpace;
    height: 190px;
    border: 2px solid $lightGrey;
    background-color: rgba($white, 0.5);
    @include respond-to($phone-media) {
      width: 260px;
    }
  }
  &_noImage svg {
    display: flex !important;
    margin: auto;
    color: $lightGrey !important;
    width: 150px !important;
    height: 150px !important;
  }
  &_noImageText {
    font-size: $mediumFontSize;
    text-align: center;
    font-weight: $mediumFontWeight;
    color: $lightGrey;
  }
}
