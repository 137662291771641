@import '../../theme/index.scss';
@import '../../theme/media.scss';

$marginRight: 10px;
$buttonWidth: 36px;

.MpSearchBar {
  display: grid;
  grid-row-gap: $smallSpace;
  grid-template-columns: 48px 48px auto 48px;

  @include respond-to($phone-media) {
    grid-template-areas: 'mpsearch_search_button mpsearch_cancel_button mpsearch_input';
  }

  @include respond-to($tablet-media, $desktop-media) {
    grid-template-areas: 'mpsearch_search_button mpsearch_cancel_button mpsearch_input mpsearch_info';
  }

  &__button {
    &_search {
      grid-area: mpsearch_search_button;
      padding: 12px 16px !important;
    }

    &_cancel {
      grid-area: mpsearch_cancel_button;
    }
  }

  &__input {
    grid-area: mpsearch_input;
    flex: 1;
    font-size: $mediumFontSize !important;
    width: auto !important;
  }

  &__info {
    grid-area: mpsearch_info;

    @include respond-to($phone-media) {
      display: none !important;
    }
  }
}
