// Resolution found here: https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488

$phone-media: 'screen and (max-width: 766px)';
$tablet-media: 'screen and (min-width: 767px) and (max-width: 1024px)';
$desktop-media: 'screen and (min-width: 1025px)';

@mixin respond-to($medias...) {
  @each $media in $medias {
    @media only #{$media} {
      @content;
    }
  }
}

:export {
  phoneMedia: $phone-media;
  tabletMedia: $tablet-media;
  desktopMedia: $desktop-media;
}
