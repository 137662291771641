.AssetSection {
  &_image {
    @apply pointer-events-none select-none;
    height: unset;
    max-width: unset;
  }
  &_logo {
    @apply absolute z-10 border-2 border-darkRed hover:cursor-move;
    background-color: rgba(233, 0, 78, 0.6);
  }
}
