// Theme
@import '../../../../../theme/index.scss';

.SaveSuccessPopUp {
  position: fixed;
  bottom: 0;
  width: 70vw;
  left: calc(50vw - 35vw);
  z-index: 1;
  opacity: 0.95;
  background-color: $primaryGreen;
  padding: $mediumSpace;
  &_clearButton {
    position: absolute !important;
    top: $smallSpace;
    right: $smallSpace;
  }
}
