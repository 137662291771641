@import '../../theme/index.scss';

@import '../../theme/mixins.scss';

.SportPriorityPage {
  display: flex;
  flex-direction: column;

  @include respond-to($desktop-media) {
    padding: $mediumSpace;
  }

  @include respond-to($tablet-media) {
    padding: $smallSpace;
  }

  @include respond-to($phone-media) {
    padding: $smallSpace 0;
  }

  &__error {
    @include respond-to($desktop-media) {
      margin: 0 $smallSpace $mediumSpace;
    }

    @include respond-to($tablet-media) {
      margin: 0 $verySmallSpace $smallSpace;
    }

    @include respond-to($phone-media) {
      margin: 0 0 $smallSpace;
    }
  }

  &__DnD_container {
    flex: 1;
  }
}
