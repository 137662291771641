@import '../../theme/index.scss';

@font-face {
  font-family: 'MierB';
  font-weight: normal;
  src: url('./MierB-Regular.otf');
}
@font-face {
  font-family: 'MierB';
  font-weight: bold;
  src: url('./MierB-Bold.otf');
}
@font-face {
  font-family: 'MierB';
  font-weight: 100;
  src: url('./MierB-Light.otf');
}
@font-face {
  font-family: 'MierB';
  font-weight: 200;
  src: url('./MierB-Light.otf');
}
@font-face {
  font-family: 'MierB';
  font-weight: 300;
  src: url('./MierB-Light.otf');
}

body {
  margin: 0;
  padding: 0;
  background-color: $backgroundColor;
  font-family: MierB, sans-serif;
}
