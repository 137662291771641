// Theme
@import '../../theme/mixins.scss';
@import '../../theme/index.scss';
@import '../../theme/media.scss';

$avatarRadius: 40;
$sideBarBackgroundColor: $black;

$headerHeight_desktop: 48px;
$headerHeight_phone: 112px;

.Homepage {
  &__menu {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &__menuTile {
      background-color: $sideBarBackgroundColor !important;
      min-width: 15rem;
      height: 18rem;
      margin: 1.5rem;

      @include respond-to($phone-media) {
        margin: 0;
        height: auto;
        width: 100%;
      }

      &__menuTitle {
        background-color: $primaryYellow;
        padding: $smallSpace;
        @include respond-to($phone-media) {
          min-height: auto;
        }
      }

      &__listContainer {
        overflow: auto;
        max-height: 17rem;
        display: flex;
        flex-direction: column;
        background-color: $sideBarBackgroundColor;
        @include respond-to($phone-media) {
          max-height: 100rem;
        }
      }
    }
  }
}
